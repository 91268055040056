import {Avatar, Card, CardActionArea, CardHeader, Divider, Grid, Typography, useTheme} from "@mui/material";
import SvgIconTelegram from "../util/SvgIconTelegram";
import SvgIconVK from "../util/SvgIconVK";
import SvgIconYouTube from "../util/SvgIconYouTube";
import SvgIconTikTok from "../util/SvgIconTikTok";
import SvgIconDTF from "../util/SvgIconDTF";
import SvgIconPikabu from "../util/SvgIconPikabu";
import SvgIconReddit from "../util/SvgIconReddit";
import SvgIconX from "../util/SvgIconX";
import React from "react";
import SvgIconBluesky from "../util/SvgIconBluesky";

function AppSocial() {
    return (
        <>
            <Typography variant="h2" align="center">Соцсети</Typography>
            <Typography align="center" mb={4}>Все свежие новости</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardActionArea
                            href="https://t.me/isaev_inc"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconTelegram/>
                                    </Avatar>
                                }
                                title="Telegram"
                                subheader="@isaev_inc"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardActionArea
                            href="https://vk.com/isaev_inc"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconVK/>
                                    </Avatar>
                                }
                                title="VK"
                                subheader="@isaev_inc"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardActionArea
                            href="https://youtube.com/@isaev_inc"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconYouTube/>
                                    </Avatar>
                                }
                                title="YouTube"
                                subheader="@isaev_inc"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardActionArea
                            href="https://tiktok.com/@isaev_inc"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconTikTok sx={{color: useTheme().palette.text.primary}}/>
                                    </Avatar>
                                }
                                title="TikTok"
                                subheader="@isaev_inc"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardActionArea
                            href="https://dtf.ru/u/1440702-isaev-space"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconDTF sx={{color: useTheme().palette.text.primary}}/>
                                    </Avatar>
                                }
                                title="DTF"
                                subheader="u/1440702-isaev-space"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardActionArea
                            href="https://pikabu.ru/@NanakiLucis"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.success.main}}>
                                        <SvgIconPikabu/>
                                    </Avatar>
                                }
                                title="Пикабу"
                                subheader="@NanakiLucis"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardActionArea
                            href="https://bsky.app/profile/isaevspace.bsky.social"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconBluesky sx={{color: useTheme().palette.text.primary}}/>
                                    </Avatar>
                                }
                                title="Bluesky"
                                subheader="@isaevspace.bsky.social"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardActionArea
                            href="https://x.com/ISAEV_SPACE"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconX sx={{color: useTheme().palette.text.primary}}/>
                                    </Avatar>
                                }
                                title="X"
                                subheader="@ISAEV_SPACE"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardActionArea
                            href="https://www.reddit.com/user/isaev_space"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconReddit/>
                                    </Avatar>
                                }
                                title="Reddit"
                                subheader="u/isaev_space"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            <Divider sx={{my: 4}}/>
        </>
    )
}

export default AppSocial
