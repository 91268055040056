import {Button, Card, CardActionArea, CardMedia, Divider, Grid, Typography} from "@mui/material";
import SvgIconBandLink from "../util/SvgIconBandLink";
import SvgIconZip from "../util/SvgIconZip";
import React from "react";
import SvgIconSteam from "../util/SvgIconSteam";
import SvgIconVKPlay from "../util/SvgIconVKPlay";
import SvgIconRuStore from "../util/SvgIconRuStore";

function AppGameVittaLucis() {
    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea
                            href="https://t.me/isaev_inc/187"
                            target="_blank"
                        >
                            <CardMedia
                                component="img"
                                image="vittalucis.jpg"
                                alt="Vitta Lucis"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h2" mb={4}>Vitta Lucis</Typography>
                    <Typography mb={4}>
                        Витта Люцис - наёмница из частной церковной гильдии, с которой связано Пророчество о Забвении.
                        Соберите отряд и отправляйтесь в приключенческий экшен в духе дарк фэнтези.
                        Отчасти вера в бога оказалась ошибкой - проклятья, демоны и прочая нечисть существуют.
                    </Typography>
                    <Typography>
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<SvgIconSteam/>}
                            href="https://store.steampowered.com/app/3187120/Vitta_Lucis"
                            target="_blank"
                            sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                        >
                            Steam
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<SvgIconVKPlay/>}
                            href="https://vkplay.ru/play/game/vitta_lucis"
                            target="_blank"
                            sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                        >
                            VK Play
                        </Button>
                        <Button
                            disabled
                            variant="outlined"
                            color="secondary"
                            startIcon={<SvgIconRuStore/>}
                            href="/"
                            target="_blank"
                            sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                        >
                            RuStore
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<SvgIconBandLink/>}
                            href="https://band.link/vittalucismaintheme"
                            target="_blank"
                            sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                        >
                            Саундтрек
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<SvgIconZip/>}
                            href="ISAEV SPACE - Vitta Lucis - Press-kit.zip"
                            target="_blank"
                            sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                        >
                            Пресс-кит
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{my: 4}}/>
        </>
    )
}

export default AppGameVittaLucis
